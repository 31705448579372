import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/components/layout/body.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/components/layouts/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/components/layouts/header/header.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/lib/providers.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/providers/ReactQueryProvider.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/home/jenkins/build-slave/drstore/drstore-web/src/ui/container.tsx")